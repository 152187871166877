import apiEndpoints from '@/models/common/api-endpoints';
import { mapGetters } from 'vuex';

export default {
  name: 'global-admin-clinics',
  components: {
  },
  data () {
    return {
      magicGUID: '00000000-0000-0000-0000-000000000000',
      editTitle: 'Edit Clinic',
      newTitle: 'Create New Clinic',
      createNewTitle: 'Create New Clinic',
      editDialog: false,
      newObjectValid: false,
      editObjectValid: false,
      newDialog: false,
      newObject: {
        name: '',
        databaseConnectionId: undefined
      },
      availableDatabasesLoading: true,
      availableDatabases: [],
      editedItem: {},
      originalItem: {},
      editedIndex: undefined,
      search: undefined,
      loadingTable: true,
      headers: [
        {
          text: 'Clinic Name',
          value: 'name',
          sortable: false,
          align: 'start'
        },
        { 
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
        {
          text: 'Links',
          value: 'links',
          sortable: false
        }
      ],
      items: undefined,
      newProcessing: false,
      editProcessing: false,
      availablePermissions: [
        'GlobalAdmin'
      ],
      rules: {
        required: value => !!value || 'A value is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid email address entered';
        },
        minLength: value => {
          return (value || '').length > 2 || 'Clinic name must contain at least 3 characters';
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      currentClinic: 'currentClinic',
      allClinics: 'allClinics'
    })
  },
  methods: {
    gotoUsers (item) {
      // match and switch to clinic
      for (let clinic of this.allClinics) {
        if (clinic.clinicId === item.clinicId) {
          this.$store.commit('setCurrentClinic', clinic);
        }
      }

      // redirect to the relevant view
      this.$router.push('adminUsers');
    },
    gotoDevices (item) {
      // match and switch to clinic
      for (let clinic of this.allClinics) {
        if (clinic.clinicId === item.clinicId) {
          this.$store.commit('setCurrentClinic', clinic);
        }
      }

      // redirect to the relevant view
      this.$router.push('adminDevices');
    },
    gotoConnectors (item) {
      // match and switch to clinic
      for (let clinic of this.allClinics) {
        if (clinic.clinicId === item.clinicId) {
          this.$store.commit('setCurrentClinic', clinic);
        }
      }

      // redirect to the relevant view
      this.$router.push('adminConnectors');
    },
    defaultNewObject () {
      const obj = {
        name: '',
        databaseConnectionId: undefined
      };

      return obj;
    },
    inviteNewObject () {
      // Reset newObject
      this.newObject = this.defaultNewObject();

      this.newDialog = true;
    },
    editItem (item) {
      // Save an in-progress version of the item to be edited
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      // persist the original version of the item
      this.originalItem = Object.assign({}, item);

      // Show the dialog
      this.editDialog = true;
    },
    deleteItem: async function (item) {
      if (!confirm('Are you sure you want to delete this clinic?')) {
        return;
      }

      const response = await apiEndpoints.deleteClinicForCurrentAPI(item.clinicId);

      if (response.result) {
        // refresh the list
        this.retrieveData();

        // refresh the info for the current user
        this.$store.dispatch('fetchAndStoreClinicAndPermissionsInfo');
      }
    },
    retrieveData: async function () {
      // set the loading state and clear the current items data
      this.loadingTable = true;
      this.items = undefined;

      // retrieve the data
      const response = await apiEndpoints.getClinicsForCurrentAPI();

      if (response.result) {
        this.items = response.data;
      }
        
      // Set the user table loading state to false
      this.loadingTable = false;
    },
    retrieveAvailableDatabases: async function () {
      // set loading true
      this.availableDatabasesLoading = true;

      // retrieve the list
      const response = await apiEndpoints.getAvailableDatabases();

      if (response.result) {
        this.availableDatabases = response.data;
        this.newObject.databaseConnectionId = this.availableDatabases[0].databaseconnectionid;
      }

      // set loading false
      this.availableDatabasesLoading = false;
    },
    save: async function () {
      
      this.editProcessing = true;

      // persist the changes
      const response = await apiEndpoints.renameClinicForCurrentAPI(this.editedItem.clinicId, this.editedItem.name);

      if (response.result) {
        // refresh the user list
        await this.retrieveData();

        // refresh the info for the current user
        await this.$store.dispatch('fetchAndStoreClinicAndPermissionsInfo');
      }

      // close the dialog
      this.close();

      this.editProcessing = false;
    },
    close () {
      this.editDialog = false;
    },
    newClose () {
      // Close the dialog
      this.newDialog = false;
    },
    newSend: async function () {
      // Set the processing status
      this.newProcessing = true;

      const response = await apiEndpoints.createClinic(this.newObject.name, this.newObject.databaseConnectionId);
      
      if (response.result) {
        // refresh the user list
        await this.retrieveData();

        // refresh the info for the current user
        await this.$store.dispatch('fetchAndStoreClinicAndPermissionsInfo');
      }

      // Set the processing status
      this.newProcessing = false;

      // Close the dialog
      this.newClose();
    }
  },
  mounted () {
    this.retrieveData();
  },
  watch: {
    currentClinic: function (newVal, oldVal) {
      this.retrieveData();
    },
    allClinics: function (newVal, oldVal) {
      this.retrieveData();
    },
    newDialog: function (newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.retrieveAvailableDatabases();
      }
    }
  }
};