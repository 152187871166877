<template>
  <v-container fluid class="smy_wrapper">
    <v-container fluid class="smy_contain">
      <v-row dense no-gutters class="table-header">
        <v-col xs=12 sm=12 md=6 lg=6 class="pb-0 mb-0 mt-0 pt-0" cols="6">
          <h2>Clinics</h2>
        </v-col>
        <v-col xs=12 sm=12 md=6 lg=6 class="pb-0 mb-0 mt-0 pt-0 d-flex justify-end" cols="6">
          <div style="width: 300px; display:inline-block">
            <v-text-field
              v-model="search"
              append-icon="$riwIconSet_search"
              label="Search"
              single-line
              hide-details
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <v-btn class="ml-4" depressed outlined color="primary" @click="inviteNewObject">{{ createNewTitle }}</v-btn>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col class="pb-0 mb-0 mt-0 pt-0" cols="12">
          <v-card class="flat">
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loadingTable"
          >
            <template v-slot:top>
              <v-dialog v-model="editDialog" max-width="500px" scrollable>
                <v-form v-model="editObjectValid" @submit.prevent>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ editTitle }}</span>
                  </v-card-title>

                  <v-card-text style="height: 100%; overflow-y: auto;">
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-if="$store.getters.isSysAdmin" v-model="editedItem.clinicId" dense label="Clinic Id" readonly outlined></v-text-field>

                            <v-text-field v-if="editDialog" v-model="editedItem.name" dense label="Clinic Name" outlined :rules="[rules.required, rules.minLength]" autofocus></v-text-field>

                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <div v-if="editProcessing">
                      Processing...<v-progress-circular class="ml-2" indeterminate color="amber"/>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close" :disabled="editProcessing">Cancel</v-btn>
                    <v-btn color="green accent-3" text @click="save" type="submit" :disabled="!editObjectValid || editProcessing">Save</v-btn>
                  </v-card-actions>
                </v-card>
                </v-form>
              </v-dialog>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class='ml-2'
                title="Edit"
                @click="editItem(item)"
              >
                $riwIconSet_edit
              </v-icon>
              <v-icon
                v-if="item.inUse === false"
                small
                class='ml-2'
                title="Delete"
                @click="deleteItem(item)"
              >
                $riwIconSet_delete
              </v-icon>
            </template>
            <template v-slot:[`item.links`]="{ item }">
              <v-icon
                small
                class='ml-2'
                title="User Admin"
                @click="gotoUsers(item)"
              >
                $riwIconSet_users
              </v-icon>
              <v-icon
                small
                class='ml-2'
                title="Device Admin"
                @click="gotoDevices(item)"
              >
                $riwIconSet_device
              </v-icon>
              <v-icon
                small
                class='ml-2'
                title="Connector Admin"
                @click="gotoConnectors(item)"
              >
                $riwIconSet_clinicConnector
              </v-icon>
            </template>
            <template v-slot:[`item.permissions`]="{ item }">
              {{ item.permissions.join(", ") }}
            </template>
          </v-data-table>
          <v-dialog v-model="newDialog" max-width="500px" scrollable persistent>
            <v-form v-model="newObjectValid" @submit.prevent>
            <v-card>
              <v-card-title>
                <span class="headline">{{ newTitle }}</span>
              </v-card-title>

              <v-card-text style="height: 100%; overflow-y: auto;">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="newObject.name" dense label="Clinic Name" outlined :rules="[rules.required, rules.minLength]" autofocus></v-text-field>
                      <v-autocomplete
                        :disabled="availableDatabases.length < 2"
                        :items="availableDatabases"
                        item-text="name"
                        item-value="databaseconnectionid"
                        :loading="availableDatabasesLoading"
                        v-model="newObject.databaseConnectionId"
                        hide-details
                        :rules="[rules.required]"
                        outlined
                        dense
                        auto-select-first
                        label="Azure Storage Service (and Location)"
                        height="30px"
                        menu-props="bottom, overflowY, offset-y"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <div v-if="newProcessing">
                  Processing...<v-progress-circular class="ml-2" indeterminate color="amber"/>
                </div>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="newClose" :disabled="newProcessing">Cancel</v-btn>
                <v-btn color="green accent-3" text @click="newSend" type="submit" :disabled="!newObjectValid || newProcessing">Create</v-btn>
              </v-card-actions>
            </v-card>
            </v-form>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </v-container>
</template>

<script>

import globalAdminClinicsViewModel from '@/view-models/global-admin-clinics-view-model';

export default globalAdminClinicsViewModel;

</script>
